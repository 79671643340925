import { Theme } from "@mui/material/styles";

const styleOverrides = (_: Theme) => {
  return `
    body {
      background-color: #EDE8E2;
    }
	`;
};

export default styleOverrides;
