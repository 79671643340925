import { Theme } from "@mui/material/styles"

const styleOverrides = (theme: Theme) => {
  return `
		.Toastify__toast-container {
			z-index: ${theme.zIndex.snackbar};
		}
	`
}

export default styleOverrides
