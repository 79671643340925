import { createTheme } from "@mui/material/styles";
import { TypographyOptions } from "@mui/material/styles/createTypography";

import generalStyles from "./general.styles";
import overridesStyles from "./overrides.styles";
import customStyles from "./custom.styles";

const muiTheme = createTheme({
  breakpoints: {
    values: {
      xl: 1776,
      lg: 1200,
      md: 900,
      sm: 600,
      xs: 0,
    },
  },
});

// 1728

const FONT_FAMILY =
  '"Univers LT", "Inria Serif", "Avenir", "EB Garamond",  sans-serif';

const typography: TypographyOptions = {
  fontFamily: FONT_FAMILY,
  h1: {
    fontFamily: FONT_FAMILY,
    fontSize: muiTheme.typography.pxToRem(60),
    letterSpacing: ".15em",
    lineHeight: "73px",
    fontWeight: 900,
    [muiTheme.breakpoints.down("md")]: {
      fontSize: muiTheme.typography.pxToRem(55),
      lineHeight: "80px",
    },
    [muiTheme.breakpoints.down("sm")]: {
      fontSize: muiTheme.typography.pxToRem(35),
      lineHeight: "46px",
    },
  },
  h2: {
    fontFamily: FONT_FAMILY,
    fontSize: muiTheme.typography.pxToRem(52),
    lineHeight: "66px",
    letterSpacing: "0.024em",
    fontWeight: 700,
    [muiTheme.breakpoints.down("sm")]: {
      fontSize: muiTheme.typography.pxToRem(42),
      lineHeight: "54px",
    },
  },
  h3: {
    fontFamily: FONT_FAMILY,
    fontSize: 48,
    lineHeight: "160.45%",
    letterSpacing: "0.02em",
    fontWeight: 700,
    [muiTheme.breakpoints.down("md")]: {
      fontSize: muiTheme.typography.pxToRem(32),
    },
    [muiTheme.breakpoints.down("sm")]: {
      fontSize: muiTheme.typography.pxToRem(30),
      lineHeight: "135%",
    },
  },
  h4: {
    fontFamily: FONT_FAMILY,
    fontSize: muiTheme.typography.pxToRem(45),
    lineHeight: "58px",
    letterSpacing: "0.02em",
    [muiTheme.breakpoints.down("md")]: {
      fontSize: muiTheme.typography.pxToRem(36),
      lineHeight: "46px",
    },
    [muiTheme.breakpoints.down("sm")]: {
      fontSize: muiTheme.typography.pxToRem(27),
      lineHeight: "35px",
    },
  },
  h5: {
    fontFamily: FONT_FAMILY,
    fontSize: muiTheme.typography.pxToRem(32),
    lineHeight: "140%",
    letterSpacing: "0.02em",
    fontWeight: 700,
    [muiTheme.breakpoints.down("md")]: {
      fontSize: muiTheme.typography.pxToRem(22),
    },
  },
  h6: {
    fontFamily: FONT_FAMILY,
    fontSize: muiTheme.typography.pxToRem(22),
    lineHeight: "28px",
    letterSpacing: "0.05em",
    fontWeight: 700,
    [muiTheme.breakpoints.down("md")]: {
      fontSize: muiTheme.typography.pxToRem(16),
      lineHeight: "20px",
    },
    [muiTheme.breakpoints.down("sm")]: {
      fontSize: muiTheme.typography.pxToRem(13),
      lineHeight: "17px",
    },
  },
  body1: {
    fontFamily: FONT_FAMILY,
    fontSize: muiTheme.typography.pxToRem(18),
    lineHeight: "190%",
    letterSpacing: "0.02em",
    [muiTheme.breakpoints.down("md")]: {
      fontSize: muiTheme.typography.pxToRem(16),
    },
  },
  body2: {
    fontFamily: FONT_FAMILY,
    fontSize: muiTheme.typography.pxToRem(16),
    lineHeight: "190%",
    letterSpacing: "0.02em",
    [muiTheme.breakpoints.down("md")]: {
      fontSize: muiTheme.typography.pxToRem(14),
    },
    [muiTheme.breakpoints.down("sm")]: {
      fontSize: muiTheme.typography.pxToRem(13),
    },
  },
  subtitle1: {
    fontFamily: FONT_FAMILY,
    fontSize: muiTheme.typography.pxToRem(22),
    lineHeight: "140%",
    letterSpacing: "0.35em",
    fontWeight: 300,
    [muiTheme.breakpoints.down("md")]: {
      fontSize: muiTheme.typography.pxToRem(18),
    },
    [muiTheme.breakpoints.down("sm")]: {
      fontSize: muiTheme.typography.pxToRem(16),
    },
  },
  subtitle2: {
    fontFamily: FONT_FAMILY,
    fontSize: muiTheme.typography.pxToRem(20),
    lineHeight: "140%",
    letterSpacing: "0.35em",
    fontWeight: 300,
    [muiTheme.breakpoints.down("md")]: {
      fontSize: muiTheme.typography.pxToRem(16),
    },
    [muiTheme.breakpoints.down("sm")]: {
      fontSize: muiTheme.typography.pxToRem(15),
    },
  },
  caption: {
    fontFamily: FONT_FAMILY,
    fontSize: muiTheme.typography.pxToRem(14),
    lineHeight: "18px",
    letterSpacing: ".05em",
    [muiTheme.breakpoints.down("md")]: {
      fontSize: muiTheme.typography.pxToRem(12),
      lineHeight: "15px",
    },
    [muiTheme.breakpoints.down("sm")]: {
      fontSize: muiTheme.typography.pxToRem(10),
      lineHeight: "13px",
    },
  },
  title1: {
    fontFamily: '"Avenir", sans-serif',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: muiTheme.typography.pxToRem(20),
    lineHeight: "27px",
    letterSpacing: "0.01em",
  },
  hugeTitle1: {
    fontFamily: '"EB Garamond", sans-serif',
    fontStyle: "normal",
    fontWeight: 800,
    fontSize: muiTheme.typography.pxToRem(40),
    lineHeight: "52px",
    textTransform: "uppercase",
    letterSpacing: "0.01em",
  },
  logo: {
    fontFamily: '"Arial MT Std", sans-serif',
    fontStyle: "normal",
    fontWeight: 800,
    fontSize: muiTheme.typography.pxToRem(27),
    lineHeight: "32px",
    letterSpacing: "0.02em",
  },
  overline: undefined,
};

typography.button = {
  fontFamily: FONT_FAMILY,
  fontSize: 14,
  lineHeight: "140%",
  letterSpacing: ".5em",
};

const defaultTheme = createTheme({
  breakpoints: muiTheme.breakpoints,
  palette: {
    background: {
      orangeGradient:
        "linear-gradient(295.25deg, #F5AF19 -40.93%, #F12711 109.59%)",
      orangeGradient1:
        "linear-gradient(284.28deg, #F5AF19 -2.27%, #F12711 101.65%)",
      angularGradient:
        "conic-gradient(from 181.13deg at 51.19% 50.39%, #FA9703 0deg, #ECD821 114.64deg, #F48F0F 182.51deg, #F7510A 278.31deg, #F89408 360deg)",
    },
    common: {
      black: "#15141B",
      grey1: "#F2F2F2",
      gray2: "#BDBDBD",
      grey3: "#828282",
      gray4: "#4F4F4F",
      grey5: "#333333",
      yellow: "#F5AF19",
      red: "#F12711",
      orange: "#FC4A1A",
    },
  },
  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: "xl",
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontSize: "1rem",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: (themeParams: any) => `
				${generalStyles(themeParams)}
				${overridesStyles(themeParams)}
        ${customStyles(themeParams)}
			`,
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          zIndex: 1350,
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: "h1",
          h2: "h2",
          h3: "h3",
          h4: "h4",
          h5: "h5",
          h6: "h6",
          subtitle1: "p",
          subtitle2: "p",
          body1: "p",
          body2: "p",
          title1: "p",
          hugeTitle1: "p",
        },
      },
    },
  },
  typography: typography,
  zIndex: {
    backdrop: 1350,
  },
  transitions: {
    duration: {
      aos: 1000,
    },
  },
  app: {},
});

export default defaultTheme;
