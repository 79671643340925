import Head from "next/head"
import { ToastContainer } from "react-toastify"
import { appWithTranslation } from "next-i18next"
import aos from "aos"
import _cloneDeep from "lodash/cloneDeep"
import _set from "lodash/set"

import nextI18NextConfig from "@@/next-i18next.config"
import { createEmotionCache, createTssEmotionCache } from "@/utils/emotion"
import defaultTheme from "@/assets/theme"
import { commonConfig } from "@/utils/config"
import { AppContext, AppContextType } from "@/contexts/app.context"

import { TssCacheProvider } from "tss-react"
import { CacheProvider, EmotionCache } from "@emotion/react"
import { CssBaseline } from "@mui/material"
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles"

import LoadingScreenOverlay from "@/components/LoadingScreenOverlay"

import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react"

import "@/assets/scss/app.scss"

import type { NextPage } from "next"
import type { AppProps } from "next/app"
import { commonHelpers } from "@/utils/helpers"
import { useRouter } from "next/router"

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: React.ReactElement, pageProps: P) => React.ReactNode
}

interface MyAppProps extends AppProps {
  Component: NextPageWithLayout
  emotionCache?: EmotionCache
  tssEmotionCache?: EmotionCache
}

const clientSideEmotionCache = createEmotionCache()
const tssClientSideEmotionCache = createTssEmotionCache()

const theme = createTheme(defaultTheme)

const InitializeApp = () => {
  const theme = useTheme()
  const router = useRouter()
  const { setFieldApp } = useContext(AppContext)!

  const handleResize = () => {
    if (commonHelpers.isMobile()) {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty("--vh", `${vh}px`)
    }
  }

  const initAos = () => {
    aos.init({
      offset: 0,
      // Global settings:
      // disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      //startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      useClassNames: true, // if true, will add content of `data-aos` as classes on scroll
      //disableMutationObserver: true, // disables automatic mutations' detections (advanced)
      //debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      //throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      //offset: 120, // offset (in px) from the original trigger point
      // delay: 200, // values from 0 to 3000, with step 50ms
      duration: theme.transitions.duration.aos, // values from 0 to 3000, with step 50ms
      //easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: true, // whether elements should animate out while scrolling past them
      //anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
    })
  }

  const handleUpdateScheme = (matcher: MediaQueryList) => () => {
    const lightSchemeIcon = document.querySelector("link#light-scheme-icon")!
    const darkSchemeIcon = document.querySelector("link#dark-scheme-icon")!
    if (matcher.matches) {
      document.head.append(darkSchemeIcon)
    } else {
      document.head.append(lightSchemeIcon)
    }
  }

  useEffect(() => {
    if (!!window) {
      handleResize()
      window.addEventListener("resize", handleResize)
      return () => {
        window.removeEventListener("resize", handleResize)
      }
    }
  }, [handleResize])

  useEffect(() => {
    setFieldApp("theme.appHeader.textColor", "default")
    setFieldApp("theme.appHeader.backgroundColor", "transparent")
  }, [router.pathname])

  useEffect(() => {
    initAos()
    if (!!window) {
      const matcher = window.matchMedia("(prefers-color-scheme: dark)")
      matcher.addListener(handleUpdateScheme(matcher))
      handleUpdateScheme(matcher)()
      return () => {
        matcher.removeListener(handleUpdateScheme(matcher))
      }
    }
  }, [])

  return null
}

const MyApp: React.FunctionComponent<MyAppProps> = props => {
  const {
    Component,
    emotionCache = clientSideEmotionCache,
    tssEmotionCache = tssClientSideEmotionCache,
    pageProps,
  } = props

  const [app, setApp] = useState<AppContextType["app"]>({
    openMenu: false,
    isMobile: commonHelpers.isMobile(),
    isTablet: commonHelpers.isMobile(),
    theme: {
      appHeader: {
        textColor: "default",
        backgroundColor: "default",
      },
    },
  })

  const setFieldApp = useCallback((field: string, value: string) => {
    setApp(prevApp => {
      const newApp = _cloneDeep(prevApp)
      _set(newApp, field, value)
      return newApp
    })
  }, [])

  const setAppThemeAppHeader: AppContextType["setAppThemeAppHeader"] =
    useCallback(payload => {
      setApp(prevApp => {
        const newApp = _cloneDeep(prevApp)
        _set(newApp, "theme.appHeader", {
          ...newApp.theme.appHeader,
          ...payload,
        })
        return newApp
      })
    }, [])

  const getLayout = Component.getLayout ?? (page => page)

  useEffect(() => {
    console.log(commonConfig.APP_VERSION)
  }, [])

  return (
    <>
      <Head>
        <title>{commonConfig.DOCUMENT_TITLE}</title>
      </Head>
      <CacheProvider value={emotionCache}>
        <TssCacheProvider value={tssEmotionCache}>
          <ThemeProvider theme={theme}>
            <AppContext.Provider
              value={{
                app,
                setApp,
                setFieldApp,
                setAppThemeAppHeader,
              }}>
              <CssBaseline />
              <InitializeApp />
              {getLayout(<Component {...pageProps} />, pageProps)}
              <LoadingScreenOverlay />
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar
                closeOnClick
              />
            </AppContext.Provider>
          </ThemeProvider>
        </TssCacheProvider>
      </CacheProvider>
    </>
  )
}

export default appWithTranslation(MyApp, nextI18NextConfig)
