import { createContext } from "react";

type AppContextApp = {
  openMenu: boolean;
  isMobile: boolean;
  isTablet: boolean;
  theme: {
    appHeader: {
      textColor: "default" | string;
      backgroundColor: "transparent" | "default" | string;
    };
  };
};

export type AppContextType = {
  app: AppContextApp;
  setApp: React.Dispatch<React.SetStateAction<AppContextApp>>;
  setFieldApp: (field: string, value: any) => void;
  setAppThemeAppHeader: (
    payload: Partial<AppContextType["app"]["theme"]["appHeader"]>
  ) => void;
};

export const AppContext = createContext<AppContextType | null>(null!);
